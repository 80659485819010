<template>
  <div :class="`allegra ${getCurrentLangs.lang}`" data-view>
    <Header/>
    <scrollAnalytics></scrollAnalytics>

    <section class="top">
      <div class="top__head-wrapper">
        <div class="top__head" v-html="$t('allegra.head')"></div>

        <router-link to="/cases" class="top__back-btn cursor-hover">
          <div class="top__arrow">

            <svg viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.5388 19.3223L14.8514 30.01L25.5484 40.7071" stroke="black" stroke-linecap="round"/>
              <path d="M15.2988 29.973L38.2398 29.9732" stroke="black" stroke-linecap="round"/>
            </svg>
          </div>

          {{ $t('back-btn') }}
        </router-link>
      </div>

      <h1 class="top__title animation iosTitle type1">
        <span class="text-wrapper" v-for="(key, value) in $t('allegra.title')" :key="value">
          <span class="letters" v-html="key"></span>
        </span>
      </h1>
    </section>

    <section class="banner">

      <picture>
        <source srcset="@/assets/img/cases/allegra/banner-mob.jpg" media="(max-width: 425px)">
        <source srcset="@/assets/img/cases/allegra/banner-tablet.jpg" media="(max-width: 768px)">
        <img v-parallax.modifier.absY="0.1" src="@/assets/img/cases/allegra/banner.jpg" alt="">
      </picture>
    </section>

    <section class="video">

      <div class="video__double-block">
        <div class="video__left">
          <p class="video__left-text" v-html="$t('allegra.video.left-text')"></p>
        </div>
        <div class="video__right">
          <p class="video__right-title" v-html="$t('allegra.video.right-title')"></p>
          <p class="video__right-text" v-html="$t('allegra.video.right-text')"></p>
          <p class="video__right-text" v-html="$t('allegra.video.right-text2')"></p>
        </div>
      </div>

      <div class="video__video-wrapper cursor-hover">
        <div class="video__play-btn cursor-hover" @click="playVideo" v-bind:class="[{ active: !videoTrigger}]"></div>
        <video v-if="getCurrentLangs.lang === 'en'"  @click="playVideo" ref="video" poster="../../assets/img/cases/allegra/video-poster.jpg">
          <source src="@/assets/video/allegra_en.mp4" type="video/mp4">
        </video>
        <video v-else @click="playVideo" ref="video" poster="../../assets/img/cases/allegra/video-poster.jpg">
          <source src="@/assets/video/allegra.mp4" type="video/mp4">
        </video>
      </div>

    </section>


    <div class="parallax parallax-race">
      <div v-parallax="0.1" class="race-bg">

        <section class="race">
          <div class="race__double-block">
            <div class="race__left">
              <p class="race__left-text" v-html="$t('allegra.race.left-text')"></p>
            </div>
            <div class="race__right">
              <p class="race__right-text" v-html="$t('allegra.race.right-text')"></p>
              <p class="race__right-bold" v-html="$t('allegra.race.right-bold')"></p>
            </div>
          </div>
        </section>

        <picture>
          <source media="(max-width:768px)" srcset="../../assets/img/cases/allegra/race-bg-tablet.png">
          <img src="../../assets/img/cases/allegra/race-bg.png">
        </picture>

      </div>
    </div>

    <section class="execution">
      <div class="execution__double-block">

        <div class="execution__left">
          <p class="execution__left-text" v-html="$t('allegra.execution.left-text')"></p>
        </div>

        <div class="execution__right">

          <p class="execution__right-text" v-html="$t('allegra.execution.right-text')"></p>

          <p class="execution__right-bold" v-html="$t('allegra.execution.right-bold')"></p>

          <div class="execution__img-wrapper">
            <img v-parallax.modifier.absY="0.02" class="execution__img first" src="../../assets/img/cases/allegra/poster1.jpg" alt="">
            <img v-parallax.modifier.absY="-0.02" class="execution__img second" src="../../assets/img/cases/allegra/poster2.jpg" alt="">
          </div>

          <p class="execution__right-bold second" v-html="$t('allegra.execution.right-bold2')"></p>

          <div class="execution__info">
            <div class="execution__info-left">
              <div class="execution__info-item" v-html="$t('allegra.execution.info-item[0]')"></div>
              <div class="execution__info-item" v-html="$t('allegra.execution.info-item[1]')"></div>
              <div class="execution__info-item" v-html="$t('allegra.execution.info-item[2]')"></div>
              <div class="execution__info-item" v-html="$t('allegra.execution.info-item[3]')"></div>
            </div>
            <div class="execution__info-right">
              <picture>
                <source media="(max-width:768px)" srcset="../../assets/img/cases/allegra/circle-mobile.png">
                <img src="../../assets/img/cases/allegra/circle.png">
              </picture>

            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="exhibition">
      <div class="exhibition__double-block">

        <div class="exhibition__left">

          <p class="exhibition__left-text" v-html="$t('allegra.exhibition.left-text')"></p>

        </div>

        <div class="exhibition__right">

          <p class="exhibition__right-text" v-html="$t('allegra.exhibition.right-text')"></p>

          <p class="exhibition__right-text last" v-html="$t('allegra.exhibition.right-text2')"></p>

          <div class="exhibition__img-wrapper" v-parallax.modifier.absY="0.05">
            <img src="../../assets/img/cases/allegra/poster3.jpg" alt="">

            <img src="../../assets/img/cases/allegra/poster4.jpg" alt="">
          </div>

        </div>

      </div>
    </section>

    <div class="parallax parallax-costume">
      <div v-parallax.modifier.absY="0.1" class="costume-bg">
        <section class="costume">
          <p class="costume__title" v-html="$t('allegra.costume.title')"></p>
          <img src="../../assets/img/cases/allegra/costume.png" alt="">
        </section>
      </div>
    </div>

    <section class="stands">
      <div class="stands__double-block">

        <div class="stands__left">

          <p class="stands__left-text" v-html="$t('allegra.costume.left-text')"></p>

          <img v-parallax.modifier.absY="-0.12" class="left" src="../../assets/img/cases/allegra/poster6.jpg" alt="">

        </div>

        <div class="stands__right">

          <p class="stands__right-text" v-html="$t('allegra.costume.right-text')"></p>

          <div class="stands__img-wrapper" v-parallax.modifier.absY="0.05">
            <img class="right" src="../../assets/img/cases/allegra/poster5.jpg" alt="">

            <img class="right mobile" src="../../assets/img/cases/allegra/poster6.jpg" alt="">
          </div>
        </div>

      </div>
    </section>

    <div class="parallax parallax-promotional">
      <div v-parallax.modifier.absY="0.1" class="promotional-bg">
        <section class="promotional">
          <p class="promotional__title" v-html="$t('allegra.promotional.title')"></p>

          <div class="promotional__double-block">
            <img class="promotional__left-img" src="../../assets/img/cases/allegra/prom1.png" alt="">
            <img class="promotional__right-img" src="../../assets/img/cases/allegra/prom2.png" alt="">
          </div>

        </section>
      </div>
    </div>

    <div class="photo-bg">
      <section class="photo">
        <div class="photo__double-block">
          <p class="photo__left-title" v-html="$t('allegra.photo.left-title')"></p>
          <div class="photo__right-text" v-html="$t('allegra.photo.right-text')"></div>
        </div>

        <img src="../../assets/img/cases/allegra/photo-img.png" alt="">
      </section>
    </div>

    <section class="vr">
      <div class="vr__double-block">
        <p class="vr__left-title" v-html="$t('allegra.vr.left-title')"></p>
        <p class="vr__right-text" v-html="$t('allegra.vr.right-text')"></p>
      </div>

      <img class="vr__big-img" src="../../assets/img/cases/allegra/vr-img.png" alt="">

      <div class="vr__double-img-block">
        <img v-parallax.modifier.absY="0.01" class="left" src="../../assets/img/cases/allegra/poster7.jpg" alt="">
        <img v-parallax.modifier.absY="-0.1" class="right" src="../../assets/img/cases/allegra/poster8.jpg" alt="">
      </div>
    </section>

    <section class="results">
      <div class="results__double-block">
        <div class="results__left">
          <p class="results__left-title" v-html="$t('allegra.results.left-title')"></p>
        </div>
        <div class="results__right">
          <div class="results__right-block block1">
            <p class="results__right-block-title type1" v-html="$t('allegra.results.right-block-title')"></p>
            <p class="results__right-block-text" v-html="$t('allegra.results.right-block-text')"></p>
          </div>
          <div class="results__right-block block2">
            <p class="results__right-block-title type1" v-html="$t('allegra.results.right-block-title2')"></p>
            <p class="results__right-block-text type2" v-html="$t('allegra.results.right-block-text2')"></p>
          </div>
          <div class="results__right-block block3">
            <p class="results__right-block-title type2" v-html="$t('allegra.results.right-block-title3')"></p>
            <p class="results__right-block-text" v-html="$t('allegra.results.right-block-text3')"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="last-poster">
      <img src="../../assets/img/cases/allegra/poster9.jpg" alt="">
    </section>

    <similar :dataSlider="similarData"></similar>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import similar from '@/components/similar.vue';
import Footer from '@/components/NewFooter.vue';
import anime from 'animejs';
import {mapGetters, mapMutations} from 'vuex';
// import getElementVisible from '@/helper';

export default {
  name: 'Allegra',
  components: {
    Header,
    Footer,
    similar,
  },

  data() {
    return {
      videoTrigger: false,
      similarData: {
        title: 'similar-title-type1',
        prevLink: "/cases/periods",
        nextLink: "/cases/listerine",
        linkShow: true,
        slider: [
          'slider-listerin',
          'slider-hepatoconstructor',
          'slider-meet-vitrolya',
          'slider-olfen',
        ],
      },

    };
  },

  mounted() {

    this.setCurrentPage('cases');

    setTimeout(() => {

      const whySection = document.querySelector('.top');
      const textWrapperAll = whySection.querySelectorAll('.text-wrapper .letters');
      textWrapperAll.forEach((e, index) => {
        const textWrapper = e;

        if (textWrapper.textContent.includes('®')) {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>").replace("®", "<sup>®</sup>");

        } else {
          textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }

        anime.timeline({loop: false})
          .add({
            targets: '.text-wrapper .letter',
            translateY: ['1.2em', 0],
            translateZ: 0,
            duration: 750,
            delay: (el, i) => 50 * i,
          });
      });
    }, 20)
  },

  methods: {
    ...mapMutations([
      'setCurrentPage',
    ]),

    playVideo() {
      const mediaVideo = this.$refs.video;
      if (mediaVideo.paused) {
        mediaVideo.play();
        gtag('event', 'scroll', {'event_category': 'play video', 'event_label': this.$route.path})

        this.videoTrigger = true;
      } else {
        mediaVideo.pause();
        this.videoTrigger = false;
      }
    },
  },

  computed: {
    ...mapGetters(['getCurrentLangs'])
  }
};
</script>

<style scoped lang="scss">
.allegra {

  margin-top: 74px;

  @media screen and (max-width: 768px) {
    margin-top: 68px;
  }
  @media screen and (max-width: 660px) {
    margin-top: 56px;
  }

  &.ua {
    .top {
      &__title {
        max-width: 80%;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }

  .parallax {
    overflow: hidden;
  }

  .top {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 88px 45px 52px 45px;

    @media screen and (max-width: 768px) {
      padding: 36px 45px 40px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 10px 20px 24px 20px;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
      @media screen and(max-width: 500px) {
        flex-direction: column-reverse;
      }

      @media screen and (max-width: 425px) {
        margin-bottom: 10px;
      }
    }
    &__back-btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 19px;

      letter-spacing: 0.04em;
      min-width: 158px;

      color: var(--text-primary-color-case);

      display: flex;
      align-items: center;

      transition: all .2s ease;

      @media screen and (min-width: 1023px) {

        &:hover {

          svg {
            path {
              stroke: #29F6D9;

            }
          }
        }
      }

      &:active {
        svg {
          path {
            stroke: #29F6D9;
          }
        }
      }

      @media screen and(max-width: 768px) {
        margin-top: 0;
      }
      @media screen and(max-width: 500px) {
        margin:  0 0 0 auto;
      }
    }

    &__arrow {
      width: 40px;
      margin-right: 10px;

      svg {
        width: 100%;
        height: auto;

        path {
          transition: all .2s ease;
          stroke: var(--text-primary-color-case);
        }

        display: block;
      }
    }

    &__head {
      line-height: 24px;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--case-allegra-color-top);
      text-align: left;
      font-weight: 500;
      font-size: 16px;
      padding: 0 0 0 0.07em;
      margin: 0 auto 0 0;


      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__title {
      font-family: "TimesNewRoman-I";
      font-size: 80px;
      text-align: left;
      font-weight: 400;

      font-style: italic;

      color: var(--text-primary-color-case);
      width: 100%;
      line-height: 0.6em;

      position: relative;

      .text-wrapper {
        position: relative;
        display: inline-block;
        overflow: hidden;
        padding: 0.1em 0.07em 0.1em 0.07em;
      }

      ::v-deep .letter {
        display: inline-block;
        line-height: .9em;
      }

      @media screen and (max-width: 768px) {
        font-size: 66px;
      }

      @media screen and (max-width: 590px) {
        font-size: 44px;
      }
    }
  }

  .banner {
    width: 100%;
    margin-top: 50px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 425px) {
      margin-top: 24px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .video {

    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 120px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 47px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {

        flex-direction: column;
      }
    }

    &__left {
      margin-right: 40px;
      min-width: 157px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);
      font-weight: 500;
      text-align: left;

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right {
      margin-left: 40px;

      max-width: 66%;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-title {
      text-align: left;
      font-size: 24px;
      line-height: 36px;
      color: var(--text-primary-color-case);
      font-weight: 500;
      margin-bottom: 8px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 425px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__right-text {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      color: var(--text-primary-color-case);
      margin-bottom: 6px;

      @media screen and (max-width: 425px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__video-wrapper {
      margin-top: 64px;
      position: relative;

      video {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &__play-btn {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;

      @media screen and (min-width: 1023px) {
        &:hover {
          &:before {
            transform: scale(1.1);
          }
        }
      }

      &.active {
        display: block;

        &:before {
          opacity: 1;
        }
      }

      &:before {
        content: 'Play';
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: flex;
        opacity: 0;

        justify-content: center;
        align-items: center;
        background: #29F6D9;

        z-index: 2;

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin: auto;

        color: #000000;

        transition: all .2s ease;

        font-family: "TimesNewRoman-I";
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.04em;

        @media screen and (max-width: 425px) {
          width: 60px;
          height: 60px;
          font-size: 16px;
        }
      }
    }
  }


  .parallax-race {
    margin-top: 85px;

    @media screen and (max-width: 768px) {
      padding: 56px 0 0 0;
      margin-top: 64px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 0 0 0;
      margin-top: 48px;
    }
  }

  .race-bg {
    background: #AB3B93;
    width: 100%;

    padding: 120px 0 0 0;

    @media screen and (max-width: 768px) {
      padding: 56px 0 0 0;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 0 0 0;
    }

    .race {

      max-width: calc(1108px + 90px);
      width: 100%;

      margin: 0 auto;

      padding: 0 45px 0 45px;

      @media screen and (max-width: 768px) {
        padding: 0 45px 0 45px;
      }

      @media screen and (max-width: 425px) {
        padding: 0 20px 0 20px;
      }

      &__double-block {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
      }

      &__left {
        margin-right: 15px;
        @media screen and (max-width: 600px) {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }

      &__left-text {
        font-size: 16px;
        line-height: 1.8em;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        color: #FFFFFF;

        font-weight: 500;
        text-align: left;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      &__right {
        margin-left: 15px;
        max-width: 66%;

        @media screen and (max-width: 600px) {
          max-width: 100%;
          margin-left: 0;
        }
      }

      &__right-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 16px;

        color: #FFFFFF;
      }

      &__right-bold {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        text-align: left;

        color: #FFFFFF;

        @media screen and (max-width: 500px) {
          font-size: 20px;
          line-height: 28px;
        }
      }

    }


    img {
      margin-top: 45px;
      width: 100%;
      height: auto;
      display: block;

      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }

      @media screen and (max-width: 500px) {
        margin-top: 28px;
      }
    }
  }

  .execution {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 90px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 56px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;
      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      text-align: left;
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    &__right {
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      color: var(--text-primary-color-case);
      margin-bottom: 8px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__right-bold {
      text-align: left;

      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      color: var(--text-primary-color-case);
      margin-bottom: 35px;

      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 28px;
      }

      &.second {
        margin-top: 48px;
        margin-bottom: 60px;

        @media screen and (max-width: 600px) {
          margin-bottom: 29px;
        }
      }
    }

    &__img-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__img {
      max-width: 50%;
      width: 100%;
      height: auto;
      display: block;

      @media screen and (max-width: 425px) {
        max-width: calc(50% - 10px);
      }

      &.first {
        margin-right: 12px;
        margin-bottom: 22px;
        @media screen and (max-width: 600px) {
          margin-right: 10px;
        }
      }

      &.second {
        margin-top: 22px;
        margin-left: 12px;
        @media screen and (max-width: 600px) {
          margin-left: 10px;
        }
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__info-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      margin-right: 15px;
      @media screen and (max-width: 600px) {
        margin-right: 0;
      }
    }

    &__info-item {

      text-align: left;
      margin: 5px 0;
      display: flex;
      flex-direction: column;

      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      color: var(--text-primary-color-case);

      @media screen and (max-width: 600px) {
        margin: 8px 0;
        font-size: 14px;
        display: inline-block;
      }

      ::v-deep i {
        font-style: normal;
      }

      ::v-deep span {
        color: var(--text-secondary-color);
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

        @media screen and (max-width: 600px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &__info-right {
      max-width: 350px;
      min-width: 240px;
      width: 100%;

      @media screen and (max-width: 600px) {
        max-width: 232px;
        margin: 16px auto 0 auto;
      }

      img {

        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
  }

  .exhibition {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 80px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 56px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      text-align: left;

      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }

    &__right {
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;

      color: var(--text-primary-color-case);
      text-align: left;

      &.last {
        margin-bottom: 48px;
        @media screen and (max-width: 500px) {
          margin-bottom: 24px;
        }
      }
    }

    img {
      margin-bottom: 24px;
      width: 100%;
      height: auto;
      display: block;

      @media screen and (max-width: 500px) {
        margin-bottom: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  .parallax-costume {
    margin-top: 100px;

    @media screen and (max-width: 768px) {
      margin-top: 64px;
    }
    @media screen and (max-width: 425px) {
      margin-top: 64px;

    }
  }

  .costume-bg {

    background: #EEC3D8;

    padding-top: 80px;

    @media screen and (max-width: 768px) {
      padding-top: 60px;
    }

    @media screen and (max-width: 425px) {
      padding-top: 40px;
    }

    .costume {
      max-width: calc(1108px + 90px);
      width: 100%;

      margin: 0 auto;

      padding: 0 45px 48px 45px;

      @media screen and (max-width: 768px) {
        padding: 0 45px 26px 45px;
      }

      @media screen and (max-width: 425px) {
        padding: 0 20px 13px 20px;
      }

      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        color: #000000;
        text-align: left;
        margin-bottom: 20px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
      }

      img {
        margin: 0 auto;
        max-width: 786px;
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .stands {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 100px 45px 150px 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 64px 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 48px 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left {
      margin-right: 15px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        margin-bottom: 24px;
      }
    }

    &__left-text {
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      text-align: left;

      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
    }

    &__right {
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__right-text {
      color: var(--text-primary-color-case);

      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      text-align: left;
      margin-bottom: 48px;

      @media screen and (max-width: 600px) {
        margin-bottom: 28px;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;

      &.left {
        margin-bottom: -20%;
        @media screen and (max-width: 768px) {
          display: none;
          margin-bottom: 0;
        }
      }

      &.right {

        &.mobile {
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
            margin-top: 20px;
          }
          @media screen and (max-width: 600px) {
            display: block;
            margin-top: 12px;
          }
        }
      }
    }
  }


  .parallax-promotional {
    background: #EEC3D8;
  }

  .promotional-bg {


    .promotional {
      max-width: calc(1108px + 90px);
      width: 100%;

      margin: 0 auto;

      padding: 100px 45px 76px 45px;

      @media screen and (max-width: 768px) {
        padding: 54px 45px 70px 45px;
      }

      @media screen and (max-width: 425px) {
        padding: 48px 20px 40px 20px;
      }

      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        text-align: left;

        color: #000000;
        margin-bottom: 34px;

        @media screen and (max-width: 768px) {
          margin-bottom: 31px;
        }
        @media screen and (max-width: 425px) {
          font-size: 12px;
          margin-bottom: 23px;
        }
      }

      &__double-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
      }

      &__left-img {
        max-width: 633px;
        min-width: 1px;
        width: 100%;
        height: auto;
        display: block;
        margin-right: 16px;

        @media screen and (max-width: 768px) {
          max-width: 385px;
        }

        @media screen and (max-width: 600px) {
          max-width: 100%;
          margin-right: 0;
        }
      }

      &__right-img {
        max-width: 445px;
        min-width: 1px;
        width: 100%;
        height: auto;
        display: block;
        margin-left: 16px;

        @media screen and (max-width: 768px) {
          max-width: 272px;
        }
        @media screen and (max-width: 600px) {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  .photo-bg {
    background: #AB3B93;

    .photo {
      max-width: calc(1108px + 90px);
      width: 100%;

      margin: 0 auto;

      padding: 100px 45px 0 45px;

      @media screen and (max-width: 768px) {
        padding: 54px 45px 39px 45px;
      }

      @media screen and (max-width: 425px) {
        padding: 48px 20px 23px 20px;
      }

      &__double-block {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
      }

      &__left-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.8em;

        letter-spacing: 0.08em;
        text-transform: uppercase;

        color: #FFFFFF;
        text-align: left;

        @media screen and (max-width: 600px) {
          margin-bottom: 24px;
        }
        @media screen and (max-width: 425px) {
          font-size: 12px;
        }
      }

      &__right-text {
        text-align: left;
        display: block;
        max-width: 66%;
        width: 100%;
        margin-left: 15px;

        font-weight: 500;
        font-size: 24px;
        line-height: 36px;

        color: #FFFFFF;

        @media screen and (max-width: 600px) {
          margin-left: 0;
          max-width: 100%;
        }
      }

      img {
        margin-top: 56px;
        width: 100%;
        height: auto;
        display: block;

        @media screen and (max-width: 768px) {
          margin-top: 68px;
        }
        @media screen and (max-width: 425px) {
          margin-top: 40px;
        }
      }
    }

  }

  .vr {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 100px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 48px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__left-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case-case);

      text-align: left;

      @media screen and (max-width: 600px) {
        margin-bottom: 24px;
      }

      @media screen and (max-width: 425px) {
        font-size: 12px;
      }
    }

    &__right-text {
      text-align: left;
      display: block;
      max-width: 66%;
      width: 100%;
      margin-left: 15px;

      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      color: var(--text-primary-color-case);

      @media screen and (max-width: 600px) {
        margin-left: 0;
        max-width: 100%;
      }
    }

    &__big-img {

      width: 100%;

      height: auto;
      display: block;

      margin-top: 40px;
      margin-bottom: calc(61px + 78px);

      @media screen and (max-width: 768px) {
        margin-bottom: calc(10px + 48px);
      }

      @media screen and (max-width: 500px) {
        margin-bottom: 40px;
      }
    }

    &__double-img-block {
      display: flex;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      img {
        max-width: 50%;
        width: 100%;
        height: auto;
        display: block;

        @media screen and (max-width: 500px) {
          max-width: 100%;
        }
      }

      .left {
        margin-right: 15px;

        @media screen and (max-width: 768px) {
          margin-right: 12px;
        }

        @media screen and (max-width: 500px) {
          margin-top: 0;
          margin-right: 0;
          margin-bottom: 12px;
        }
      }

      .right {
        margin-left: 15px;

        @media screen and (max-width: 768px) {
          margin-left: 12px;
        }

        @media screen and (max-width: 500px) {
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }
  }

  .results {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 130px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 112px 45px 0 45px;
    }

    @media screen and (max-width: 500px) {
      padding: 112px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 56px 20px 0 20px;
    }

    &__double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__left-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.8em;

      letter-spacing: 0.08em;
      text-transform: uppercase;

      color: var(--text-primary-color-case);

      text-align: left;

      @media screen and (max-width: 768px) {
        margin-bottom: 24px;
        font-size: 12px;
      }
    }

    &__right {

      max-width: 66%;
      width: 100%;
      margin-left: 15px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100%;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 100%;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &__right-block {

      max-width: 42%;
      width: 100%;

      @media screen and (max-width: 600px) {
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 40px !important;
      }

      &.block1 {
        margin-right: 20px;
        @media screen and (max-width: 768px) {
          max-width: calc(44% - 20px);
        }
      }

      &.block2 {
        margin-left: 20px;
        @media screen and (max-width: 768px) {
          max-width: calc(52% - 20px);
        }
      }

      &.block3 {
        margin-top: -30px;
        max-width: 349px;

        @media screen and (max-width: 768px) {
          margin-top: 67px;
        }
        @media screen and (max-width: 600px) {
          margin-top: 0;
          margin-bottom: 0 !important;
        }
      }
    }

    &__right-block-title {
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;

      color: var(--text-primary-color-case);
      margin-bottom: 16px;
      text-align: left;

      &.type2 {
        font-weight: 400;
        font-size: 64px;
        line-height: 88px;
        @media screen and (max-width: 600px) {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }

    &__right-block-text {
      text-align: left;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;

      @media screen and (max-width: 600px) {
        font-size: 16px;
      }

      &.type2 {
        max-width: 309px;
        width: 100%;
        @media screen and (max-width: 600px) {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  .last-poster {
    max-width: calc(1108px + 90px);
    width: 100%;

    margin: 0 auto;

    padding: 100px 45px 0 45px;

    @media screen and (max-width: 768px) {
      padding: 54px 45px 0 45px;
    }

    @media screen and (max-width: 425px) {
      padding: 56px 20px 0 20px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

}
</style>
